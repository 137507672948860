import { ChangeEvent, useState } from 'react'
import { useAdministrationUserResetPassword } from '../../hooks/api/administration/useAdministrationUserResetPassword'
import { useTranslation } from 'next-i18next'
import Button from '../base/button/Button'
import ConfirmDialog from '../base/confirm-dialog/ConfirmDialog'
import TextField from '../base/text-field/TextField'

export const RequestResetPasswordButton = () => {
    const { t } = useTranslation()
    const [email, setEmail] = useState<string>()
    const { mutate: resetPassword } = useAdministrationUserResetPassword()

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }

    const onConfirm = () => {
        if (email) {
            resetPassword({ email })
        }
    }

    const renderDialogTextAndInput = () => {
        return (
            <div>
                <span className='text-sm'>{t('frontend.dashboard.administration.forgotten_password.modal_text')}</span>
                <TextField type='email' placeholder='Email' value={email} onChange={onChange} />
            </div>
        )
    }

    return (
        <ConfirmDialog
            text={renderDialogTextAndInput()}
            okLabel={t('frontend.dashboard.administration.forgotten_password.send_request')}
            cancelLabel={t('frontend.dashboard.administration.forgotten_password.cancel_request')}
            onConfirm={onConfirm}
        >
            {open => {
                return (
                    <Button
                        as='a'
                        target='_blank'
                        variant='text'
                        className='w-full !text-base !font-medium'
                        onClick={open}
                    >
                        {t('frontend.dashboard.administration.forgotten_password.button_title')}
                    </Button>
                )
            }}
        </ConfirmDialog>
    )
}
