import * as yup from 'yup'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { FieldPath } from 'react-hook-form/dist/types/path'
import { LoginRequestData, useSignIn } from '../../auth/auth'
import { RequestResetPasswordButton } from './RequestResetPasswordButton'
import { handleFormErrorsFromAxios } from '../../utils/ApiHelper'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import Alert from '../base/alert/Alert'
import Button from '../base/button/Button'
import Column from '../base/grid/Column'
import Form from '../base/form/Form'
import React from 'react'
import Row from '../base/grid/Row'
import TextField from '../base/text-field/TextField'
import useTheme from '../../hooks/useTheme'

const LoginForm = () => {
    const { t } = useTranslation()
    const { signIn, isLoading } = useSignIn<LoginRequestData>()
    const {
        theme: { oldSystemUrl }
    } = useTheme()

    const {
        handleSubmit,
        register,
        formState: { errors },
        setError
    } = useForm<LoginRequestData>({
        resolver: yupResolver(
            yup.object({
                username: yup.string().required(t('frontend.user.login.required')),
                password: yup.string().required(t('frontend.user.password.required'))
            })
        ),
        reValidateMode: 'onChange'
    })

    const submit = (data: LoginRequestData) => {
        return signIn(data).catch(error => {
            return handleFormErrorsFromAxios(error, setError)
        })
    }

    const getFieldError = (path: FieldPath<LoginRequestData>) => {
        return errors[path]?.message
    }

    return (
        <Form className='space-y-6' onSubmit={handleSubmit(submit)}>
            <Row>
                <Column cols={12}>
                    <Alert color='info' className='text-body-small'>
                        <div className='mt-1'>
                            Pro přihlášení použijte své aktuální přihlašovací údaje ze současného systému.
                        </div>
                    </Alert>
                </Column>
                <Column cols={12}>
                    <TextField
                        label={t('frontend.user.login')}
                        type='text'
                        inputClassName='!py-3.5 !bg-grey-100 !border-none'
                        message={getFieldError('username')}
                        valid={!getFieldError('username')}
                        {...register('username')}
                    />
                </Column>
                <Column cols={12}>
                    <TextField
                        label={t('frontend.user.password')}
                        inputClassName='!py-3.5 !bg-grey-100 !border-none'
                        type='password'
                        message={getFieldError('password')}
                        valid={!getFieldError('password')}
                        {...register('password')}
                    />
                </Column>
            </Row>
            <Row>
                <Column cols={12}>
                    <Button type='submit' className='w-full !py-2.5 !text-base !font-medium' loading={isLoading}>
                        {t('frontend.user.login_button')}
                    </Button>
                </Column>
                <Column cols={12}>
                    <Button
                        as='a'
                        href={oldSystemUrl}
                        target='_blank'
                        variant='text'
                        className='w-full !py-2.5 !text-base !font-medium'
                        loading={isLoading}
                        rightIcon={<ChevronRightIcon />}
                    >
                        {t('frontend.user.go_to_old_system')}
                    </Button>
                </Column>
                <Column cols={12}>
                    <RequestResetPasswordButton />
                </Column>
            </Row>
        </Form>
    )
}

export default LoginForm
